import React, { useContext, useEffect, useState } from "react";
import { HeaderComponent, Sidebar } from "../components";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { Store } from "../context/Store/Store";
import { ethers } from "ethers";

const ReferralHistory = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { getUserEventsHistory, userDatabaseData, userHistoryData } = useContext(Store);
  const { address, chainId, isConnected } = useWeb3ModalAccount();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    getUserEventsHistory();
  }, [address]);
    // Logic to adjust the displayed level for users (relative to the starting level)
    const startingLevel = userDatabaseData?.userLevel || 1; // First user's level
    const adjustedLevel = (userLevel) => userLevel - startingLevel; // Display relative levels

  return (
    <>
      <div style={{ display: "flex" }} className="backOffice">
        {/* Sidebar */}
        <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

        {/* Main Content Area */}
        <div
          style={{
            marginLeft: isSidebarOpen ? "250px" : "80px",
            width: "100%",
            transition: "margin-left 0.3s ease",
          }}
        >
          <HeaderComponent isSidebarOpen={isSidebarOpen} />

          <div className="content">
            <div style={{ padding: "20px", paddingTop: "100px" }}>
              <div className="breadcrum">
                <div className="table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>name</th>
                        <th>Buyer Address</th>
                        <th>Referral Amount</th>
                        <th>Level</th>
                        <th>Date</th>
                        <th>Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userHistoryData?.referralHistory?.length > 0 ? (
                        userHistoryData?.referralHistory?.map((row, index) => (
                          <tr key={index}>
                            {/* buyer */}

                            <td>{row?.buyer?.user_name || "N/A"}</td>

                            {/* Displaying wallet address */}
                            <td>
                              {`${row?.buyer_address?.slice(
                                0,
                                6
                              )}....${row?.buyer_address?.slice(-6)}` || "N/A"}
                            </td>

                            {/* Displaying buy amount */}
                            <td>
                              {row?.tokenAmount || "0"}
                            </td>

                            {/* Displaying level (this assumes you have a 'level' property in your data) */}
                            <td>{adjustedLevel(row?.buyer?.level) || "N/A"}</td>
                            {/* Displaying date */}
                            <td>
                              {new Date(row?.createdAt).toLocaleDateString() ||
                                "N/A"}
                            </td>
                               {/* Displaying date */}
                               <td>{row?.eventType}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5" style={{ textAlign: "center" }}>
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferralHistory;
