import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
// CSS IMPORT
import "./assets/CSS/style.css";
import "./assets/CSS/responsive.css";
import {
  AirdropHistory,
  BackOffice,
  BuyDVT,
  BuyHistory,
  BuyIco,
  ClaimDVT,
  ClaimHistory,
  Home,
  IcoHistory,
  ReferralHistory,
  SwapDVT,
  SwapHistory,
} from "./screens";
import { ConfirmationModal, DirectTeamComp, TotalTeamComp } from "./components";
import Loader from "./components/Loader";
import AdminAirdrop from "./AdminDashboard/AdminAirdrop";

const App = () => {

  const tableTransactionData = [
    {
      user_name: "JohndddddddddddddddddDoe",
      phone_number: "123-456-7890000000000",
      email: "subhisingh211015@gmail.com",
      wallet_address: "0x1234567890abcdef",
      usdtAmount: "1000",
      buyTokens: "1000"
    },
  ];

  return (
    <>
      <Routes>
        <Route path="/:referral_code" element={<Home />} />
        <Route path="/*" element={<Home />} />
        <Route path="/dashboard" element={<BackOffice />} />
        <Route path="/direct-team" element={<DirectTeamComp />} />
        <Route path="/total-team" element={<TotalTeamComp />} />
        
        {/* FINANCE */}
        <Route path="/buy-dvt" element={<BuyDVT />} />
        <Route path="/claim-dvt" element={<ClaimDVT />} />
        <Route path="/buy-ico" element={<BuyIco />} />
        <Route path="/swap-dvt" element={<SwapDVT />} />

        {/* HISTORY */}
        <Route path="/swap-history" element={<SwapHistory />} />
        <Route path="/ico-history" element={<IcoHistory />} />
        <Route path="/airdrop-history" element={<AirdropHistory />} />

        <Route path="/claim-history" element={<ClaimHistory />} />
        <Route path="/buy-history" element={<BuyHistory />} />
        <Route path="/referralReward-history" element={<ReferralHistory />} />

        
        {/* AIRDROP BY ADMIN */}
        <Route path="/admin-airdrop" element={<AdminAirdrop />} />

      </Routes>

      <ConfirmationModal/>
    </>
  );
};

export default App;
