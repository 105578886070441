import React, { useContext, useState } from "react";
import { HeaderComponent, Sidebar } from "../../components";
import { Store } from "../../context/Store/Store";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import apis from "../../context/Services";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";

const BuyIco = () => {
  const { BuyICOWithUSDT, loader, userDatabaseData,icoContractData, userContractData } = useContext(Store);
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [dvtAmount, setDvtAmount] = useState(0);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleBuyICODoofy = async (_amount) => {
    
    if (!userDatabaseData?.isProfileCompleted) {
      return toast.error(
        "Please Completed Your Profile by Go To Profile Section"
      );
    }
    if (address && isConnected) {
      if (_amount <= 10) {
        return toast.error("Minimum buy should be greater than $10");
      }
      try {
        let listAddress = await apis.getPlanBuyingReferrals(address?.toString());
        console.log(
          listAddress?.data?.parentAddresses,
          "listAddresslistAddresslistAddress`"
        );
        console.log(
          listAddress?.data?.parentAddresses.length,
          "listAddress?.data?.lengtg"
        );
        console.log(_amount, "planBuyplanBuyplanBuyplanBuyplanBuy`");
        BuyICOWithUSDT(listAddress?.data?.parentAddresses, _amount);
      } catch (error) {
        console.log(error, "error");
        toast.error("get api fail", error);
      }
    } else {
      toast.error("Please connect your walllet");
    }
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div style={{ display: "flex" }} className="backOffice">
          {/* Sidebar */}
          <Sidebar
            isSidebarOpen={isSidebarOpen}
            toggleSidebar={toggleSidebar}
          />

          {/* Main Content Area */}
          <div
            style={{
              marginLeft: isSidebarOpen ? "250px" : "80px",
              width: "100%",
              transition: "margin-left 0.3s ease",
            }}
          >
            <HeaderComponent isSidebarOpen={isSidebarOpen} />

            <div className="content">
              <div style={{ padding: "20px", paddingTop: "100px" }}>
                <h1 className="buy-ico">Buy DVT</h1>
                <div className="finance-buy-send-card">
                  <div className="send-recieved-con">
                    <h2>Sending Amount</h2>
                    {/* <h2>DVT</h2> */}
                    <div className="text-wrap">
                      <h3>{dvtAmount}</h3>
                      {/* <h3>DVT</h3> */}
                      <img
                        src="/assets/images/USDT.png"
                        alt="USDT"
                        width={35}
                      />
                    </div>
                  </div>
                  <div className="send-recieved-con">
                    <h2>Recieved Amount</h2>
                    {/* <h2>USDT</h2> */}
                    <div className="text-wrap">
                      {/* <h3>USDT</h3> */}
                      <h3>{dvtAmount / +icoContractData?.icoSalePrice}</h3>
                      <img
                        src="/assets/images/DSmall.png"
                        alt="USDT"
                        width={35}
                      />
                    </div>
                  </div>
                  <div className="send-recieved-con">
                    <h2>Account Balance</h2>
                    <div className="text-wrap">
                      <h3> {icoContractData?.usdtInWallet || 0} </h3>
                      <img
                        src="/assets/images/USDT.png"
                        alt="USDT"
                        width={35}
                      />
                    </div>
                  </div>
                  <div className="input-con">
                    <h2>Buy DVT Amount</h2>
                    <div className="wrap">
                      {/* <span>DVT</span> */}
                      <img
                        src="/assets/images/USDT.png"
                        alt="USDT"
                        width={35}
                      />
                      <input
                        type="number"
                        name=""
                        id=""
                        onChange={(e) => setDvtAmount(e.target.value)}
                        placeholder="Amount in USDT"
                      />
                    </div>
                  </div>
                  <div className="price-con">
                    <h2>1DVT = {icoContractData?.icoSalePrice} USDT</h2>
                    <p>The receivable amount is {dvtAmount / +icoContractData?.icoSalePrice} DVT</p>
                  </div>
                  <div className="btn-wrap">
                    <button onClick={() => handleBuyICODoofy(dvtAmount)}>
                      Buy
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BuyIco;
