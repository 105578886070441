import React, { useContext, useEffect, useState } from "react";
import { HeaderComponent, Sidebar } from "../../components";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { Store } from "../../context/Store/Store";

const AirdropHistory = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { getUserEventsHistory, userHistoryData } = useContext(Store);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const { address, chainId, isConnected } = useWeb3ModalAccount();

  useEffect(() => {
    getUserEventsHistory();
  }, [address]);
  return (
    <>
      <div style={{ display: "flex" }} className="backOffice">
        {/* Sidebar */}
        <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

        {/* Main Content Area */}
        <div
          style={{
            marginLeft: isSidebarOpen ? "250px" : "80px",
            width: "100%",
            transition: "margin-left 0.3s ease",
          }}
        >
          <HeaderComponent isSidebarOpen={isSidebarOpen} />

          <div className="content">
            <div style={{ padding: "20px", paddingTop: "100px" }}>
              <div className="breadcrum">
                <div className="table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>Transaction Hash</th>
                        <th></th>
                        <th>Recived DVT</th>
                        <th></th>
                        <th>Date</th>
                        {/* <th>Wallet Address</th> */}
                        {/* <th>Level</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {userHistoryData?.airdropHistory?.length > 0 ? (
                        userHistoryData?.airdropHistory?.map((row, index) => (
                          <tr key={index}>
                            {/* Displaying wallet address */}
                            <td>
                              <a
                                href={`https://testnet.bscscan.com/tx/${row?.eventTransactionHash}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {`${row?.eventTransactionHash?.slice(
                                  0,
                                  6
                                )}....${row?.eventTransactionHash?.slice(
                                  -6
                                )}` || "N/A"}
                              </a>
                            </td>
                            {/* `${userContractData?.dvtBuy_tokens * 100 / 600000000}%`, */}
                            {/* Displaying buy amount */}
                            {/* <td>{`${row?.tokenAmount * 100  / 2500000000}%` || "N/A"}</td> */}
                            <td></td>

                            {/* Displaying buy amount */}
                            <td>{row?.tokenAmount || "0"}</td>

                            {/* Displaying buy amount */}
                            <td></td>

                            {/* Displaying date */}
                            <td>
                              {new Date(row?.createdAt).toLocaleDateString() ||
                                "N/A"}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5" style={{ textAlign: "center" }}>
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AirdropHistory;
