import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useWeb3Modal, useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { Store } from "../context/Store/Store";
import Loader from "../components/Loader";
import apis from "../context/Services";

const Home = () => {
  const nevigate = useNavigate();
  const { referral_code } = useParams(); // Extract the address from the URL
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { loader, setloader, setReferraAddress, setShowConfirmationModal } = useContext(Store);
  const { open } = useWeb3Modal();

  useEffect(() => {
    const connectWallet = async () => {
      if (isConnected && address) {
        if (referral_code) {
          setShowConfirmationModal(true);
          setReferraAddress(referral_code);
        } else {
          try {
            let data = {
              wallet_address: address?.toString(),
              parent_wallet_address: referral_code
                ? referral_code?.toString()
                : "0x6c87a464cDB6E8BeF6D07699a5c71CaF027Ab551", //TODO:: 0x6c87a464cDB6E8BeF6D07699a5c71CaF027Ab551
            };
            const response = await apis.connectWallet(data);
            if (response.status) {
              nevigate("/dashboard");
            }
          } catch (error) {
            // nevigate("/dashboard")
            console.error("Error connecting user:", error.message);
          }
        }
      }
    };
    connectWallet();
  }, [address]);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="home">
          <div className="home-wrap">
            <div className="cardd">
              <img src="/assets/images/logo.png" alt="logo" />
              <h1>WELCOME TO DVT</h1>
              <p>Login and Register</p>
              <div onClick={() => open()} className="btn-wrapper">
                <button>Connect Wallet</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
